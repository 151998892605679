import {generateDefaultName, reportNameGenerator} from "@/utils/reportNameGenerator";

const google_protobuf_struct_pb = require("google-protobuf/google/protobuf/struct_pb.js");

import { socioGrpcClient } from "@/setup/socioGrpcClient";
import { StandByReport, Observation } from "@socotec.io/socio-vue-components";
import { renameKeys, structToJsObj } from "@/utils/utilsGrcpRest";

const state = {
  standByReportsCount: 0,
  observationsCount: 0,
  generatingStandbyReport: false,
};

const getters = {
  getStandByReportsCount: (state) => {
    return state.standByReportsCount;
  },
  getObservationsCount: (state) => {
    return state.observationsCount;
  },
  isReportGenerating: (state) => {
    return state.generatingStandbyReport;
  },
};

const actions = {
  /**
   * Create a standby by report
   * @param [rootGetters]
   * @param reportTemplate
   * @param projectId
   * @param reportGenerationData
   * @returns {Promise<*>}
   */
  async createStandByReport(
    { rootGetters },
    { reportTemplate, project, reportGenerationData }
  ) {
    const request =
      new socioGrpcClient.rapsosps_back.standbyreport.StandByReport();
    if (typeof reportGenerationData === "undefined") {
      reportGenerationData = new google_protobuf_struct_pb.Struct();
    } else {
      reportGenerationData =
        new google_protobuf_struct_pb.Struct.fromJavaScript(
          // Necessary because the fromJavascript method only supports the object type
          // and not class instances (Like vuex ORM models)
          JSON.parse(JSON.stringify(reportGenerationData))
        );
    }

    const reportName = generateDefaultName(project.projectName, reportTemplate.name);

    request.setReportGenerationData(reportGenerationData);
    request.setUser(rootGetters["user/getCurrentUser"].email);
    request.setUsermanagementUuid(
      rootGetters["user/getCurrentUser"].usermanagementUuid
    );
    request.setTemplate(reportTemplate.uuid);
    request.setProject(project.uuid);
    request.setReportName(reportName);

    const response =
      await socioGrpcClient.rapsosps_back.standbyreport.StandByReportControllerPromiseClient.create(
        request,
        {}
      );
    const standByReport = await StandByReport.insert({
      data: response.toObject(),
    });

    return standByReport.standByReport[0];
  },

  /**
   * Delete a standby report
   * @param rootGetters
   * @param commit
   * @param standByReportUuid
   * @returns {Promise<*>}
   */
  async deleteStandByReport({ rootGetters, commit }, standByReportUuid) {
    const request =
      new socioGrpcClient.rapsosps_back.standbyreport.StandByReport();

    request.setUuid(standByReportUuid);

    await socioGrpcClient.rapsosps_back.standbyreport.StandByReportControllerPromiseClient.destroy(
      request,
      {}
    );

    commit(
      "UPDATE_STANDBY_REPORT_COUNT",
      rootGetters["standByReport/getStandByReportsCount"] - 1
    );

    return await StandByReport.delete(standByReportUuid);
  },

  /**
   * Fetch standby reports
   * @param commit
   * @param metadata
   * @returns {Promise<*>}
   */
  async fetchStandByReportList({ commit }, metadata) {
    const request =
      new socioGrpcClient.rapsosps_back.standbyreport.StandByReportListRequest();

    const response =
      await socioGrpcClient.rapsosps_back.standbyreport.StandByReportControllerPromiseClient.list(
        request,
        metadata
      );

    commit("UPDATE_STANDBY_REPORT_COUNT", response.getCount());

    const responseObject = response.getResultsList();
    /*
       We have to iterate over all element to use the function toJavaScript()
       on the JSON FIELD to have a friendly javascript output
       in other case the grpc give something like that:
        "reportGenerationData": {
          "fieldsMap": [
            [ "test", { "nullValue": 0, "numberValue": 0, "stringValue": "test", "boolValue": false } ],
            [ "title", { "nullValue": 0, "numberValue": 0, "stringValue": "Titre", "boolValue": false } ]
          ]
        }
        That's why we don't use directly response.toObject().resultList
      */
    const standByReportsJavascriptList = responseObject.map((element) => {
      const el = element.toObject();
      el.reportGenerationData = element
        .getReportGenerationData()
        .toJavaScript();
      return el;
    });

    return await StandByReport.insert({
      data: standByReportsJavascriptList,
    });
  },

  async updateStandByReport({ rootGetters }, { instance }) {
    const reportName = reportNameGenerator(instance.reportName.split(" - ")[0], instance.template, instance.reportGenerationData)
    const request =
      new socioGrpcClient.rapsosps_back.standbyreport.StandByReport();
    request.setUser(rootGetters["user/getCurrentUser"].email);
    request.setUsermanagementUuid(
      rootGetters["user/getCurrentUser"].usermanagementUuid
    );
    request.setTemplate(instance.template);
    request.setProject(instance.project);
    request.setUuid(instance.uuid);
    request.setReportName(reportName);
    const reportGenerationData =
      new google_protobuf_struct_pb.Struct.fromJavaScript(
        // Necessary because the fromJavascript method only supports the object type
        // and not class instances (Like vuex ORM models)
        JSON.parse(JSON.stringify(instance.reportGenerationData))
      );
    request.setReportGenerationData(reportGenerationData);
    try {
      const response =
        await socioGrpcClient.rapsosps_back.standbyreport.StandByReportControllerPromiseClient.update(
          request,
          {}
        );
      return await StandByReport.update({
        where: instance.uuid,
        data: {
          ...response.toObject(),
        },
      });
    } catch (err) {
      console.log(err);
    }
  },

  /**
   * Retrieve a standby report
   * @param [_]
   * @param standByReportUuid {string}
   * @returns {Promise<StandByReport>}
   */
  async retrieveStandByReport(_, standByReportUuid) {
    const request =
      new socioGrpcClient.rapsosps_back.standbyreport.StandByReport();

    request.setUuid(standByReportUuid);

    const response =
      await socioGrpcClient.rapsosps_back.standbyreport.StandByReportControllerPromiseClient.retrieve(
        request,
        {}
      );
    const standByReportData = response.toObject();

    standByReportData.reportGenerationData = response
      .getReportGenerationData()
      .toJavaScript();

    const standByReport = await StandByReport.insert({
      data: standByReportData,
    });

    return standByReport.standByReport[0];
  },

  async buildReportGenerationData(context, standByReport) {
    const request = socioGrpcClient.javascriptToRequest(
      socioGrpcClient.rapsosps_back.standbyreport
        .BuildReportGenerationDataRequest,
      standByReport
    );

    const response =
      await socioGrpcClient.rapsosps_back.standbyreport.StandByReportControllerPromiseClient.buildReportGenerationData(
        request,
        {}
      );

    const { reportGenerationData } = structToJsObj(response);
    return { reportGenerationData, response };
  },

  /**
   * Request a report generation
   * @param [dispatch, commit]
   * @param standByReportUuid Report uuid
   * @returns {Promise<any>}
   */
  async generateStandByReport(
    { dispatch, commit },
    { standByReportUuid, metadata = {}, autoFetch = true }
  ) {
    const request =
      new socioGrpcClient.rapsosps_back.standbyreport.StandByReport();

    request.setUuid(standByReportUuid);

    commit("SET_GENERATING_STANDBYREPORT", true);
    try {
      const stream =
        await socioGrpcClient.rapsosps_back.standbyreport.StandByReportControllerPromiseClient.generate(
          request,
          metadata
        );

      stream.on("data", async (response) => {
        let action = {
          [StandByReport.STATUS_CODE.SUCCESS]: async () => {
            await StandByReport.delete(standByReportUuid);
            commit("SET_GENERATING_STANDBYREPORT", false);
          },
          [StandByReport.STATUS_CODE.GENERATION_FAILED]: () => {
            commit("SET_GENERATING_STANDBYREPORT", false);
          },
          [StandByReport.STATUS_CODE.UPLOAD_FAILED]: () => {
            commit("SET_GENERATING_STANDBYREPORT", false);
          },
          [StandByReport.STATUS_CODE.ERROR]: () => {
            commit("SET_GENERATING_STANDBYREPORT", false);
          },
          [StandByReport.STATUS_CODE.GENERATING]: () => {},
        };

        action[response.getCode()]();

        if (autoFetch) {
          await dispatch(
            "report/fetchReport",
            { reportUuid: response.toObject().data.uuid },
            { root: true }
          );
        }
      });

      return stream;
    } catch (err) {
      commit("SET_GENERATING_STANDBYREPORT", false);
      console.error(err);
    }
  },

  async fetchObservations({ commit }, { metadata, operationId }) {
    // Delete front database for this project to reload correctly observations re fetched
    Observation.delete((observation) => {
      return observation.project === operationId;
    });
    const request =
      new socioGrpcClient.rapsosps_back.standbyreport.ObservationListRequest();

    try {
      const response =
        await socioGrpcClient.rapsosps_back.standbyreport.ObservationControllerPromiseClient.list(
          request,
          {
            ...metadata,
          }
        );
      const responseObject = response.toObject();
      commit("UPDATE_OBSERVATIONS_COUNT", responseObject.count);
      let observations = responseObject.resultsList;
      const keysMap = {
        contributorsList: "contributors",
        documentsList: "documents",
        risksList: "risks",
      };
      observations = observations.map((observation) => {
        return renameKeys(keysMap, observation);
      });
      // Insert all objects instance
      return await Observation.insert({
        data: observations.reverse(),
      });
    } catch (err) {
      console.error(err);
    }
  },
};

const mutations = {
  UPDATE_STANDBY_REPORT_COUNT: function (state, newTotal) {
    state.standByReportsCount = newTotal;
  },
  UPDATE_OBSERVATIONS_COUNT: function (state, newTotal) {
    state.observationsCount = newTotal;
  },
  SET_GENERATING_STANDBYREPORT: function (state, newValue) {
    state.generatingStandbyReport = newValue;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
