<template>
  <VApp>
    <SocioBaseNotification />
    <SocioMainNavbar
      home-page-router-name="home"
      :nav-items="navItems"
      :oidc-error="networkError"
      :current-user="oidcUser"
      avatar-color="#A9C409"
      @authenticateOidcSilent="authenticateOidcSilent"
      @signOutOidc="signOutOidc"
      @support-link-confirmation="
        (link) => {
          supportLink = link;
        }
      "
      :support-links="supportLinks"
      :support-form-href="supportFormHref"
      :support-center-href="supportCenterHref"
    >

      <template #additional-navbar-element>
        <div class="d-flex align-center px-1">
          <span class="text-subtitle-2 font-weight-regular pr-2">
            {{ $t("onlineMode") }}
            <vIcon left dense class="mx-2" :color="cloudIconColor">{{
              cloudIcon
            }}</vIcon>
          </span>
          <vSwitch
            v-model="isOnline"
            @change="openConfirmOfflineDialog"
            inset
            dense
            hide-details="true"
            class="pa-0 ma-0 v-switch"
          ></vSwitch>
          <VDivider class="ml-2" vertical></VDivider>
        </div>
      </template>
    </SocioMainNavbar>
    <SocioSupportLinkConfirmation v-model="supportLink" />
    <VMain id="app-main">
      <RouterView />
      <OfflineDialog v-if="showOfflineDialog" :reports="savedReports" @back-online="backOnline"/>
      <BackOnlineDialog v-if="showBackOnlineDialog" :reports="savedReports" />
      <ConfirmOfflineDialog
        v-model="showConfirmOfflineDialog"
        :isOnline="isOnline"
        :pending="pending"
        @confirm="confirmNewOnlineStatus"
        @cancel="cancelNewOnlineStatus"
      />
    </VMain>
    <VSnackbar
      v-model="newAppIsAvailable"
      :vertical="true"
      :right="true"
      min-height="200"
      timeout="-1"
      class="new-app-available"
    >
      <div class="content">{{ $t("newAppText") }}</div>
      <template #action="{ attrs }">
        <VBtn color="blue" v-bind="attrs" @click="update">
          {{ $t("refresh") }}
        </VBtn>
      </template>
    </VSnackbar>
  </VApp>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { useMainNavigations } from "@/composables/navigations/useMainNavigations";
import useOfflineMode from "@/composables/useOfflineMode";
import i18n from "@/setup/i18n";
import OfflineDialog from "./partials/offline-mode/OfflineDialog.vue";
import BackOnlineDialog from "./partials/offline-mode/BackOnlineDialog.vue";
import ConfirmOfflineDialog from "./partials/offline-mode/ConfirmOfflineDialog.vue";
import { debounce } from "lodash";
export default {
  name: "RapsoSPS",
  setup() {
    const { navItems } = useMainNavigations(i18n);
    const { showBackOnlineDialog, showOfflineDialog, savedReports } =
      useOfflineMode(undefined);

    return {
      navItems,
      showBackOnlineDialog,
      showOfflineDialog,
      savedReports,
    };
  },

  components: {
    OfflineDialog,
    BackOnlineDialog,
    ConfirmOfflineDialog,
  },
  data() {
    return {
      newAppIsAvailable: false,
      supportLinks: [
        {
          label: this.$t("training_request"),
          mailto: "max.abiven@socotec.com",
          body: "",
        },
        {
          label: this.$t("service_request"),
          href: "https://socotec--bmcservicedesk.visualforce.com/apex/BMCServiceDesk__SelfServiceNew?fromSiteUrl=#/support/problem-requests/all?id=a3H7S000000TeiLUAS",
        },
        {
          label: this.$t("declare_incident"),
          href: "https://socotec--bmcservicedesk.visualforce.com/apex/ssredirect?type=tile&id=newinc",
          event: "support-link-confirmation",
          body: this.$t("incident_confirmation"),
        },
      ],
      supportLink: null,
      supportFormHref:
        "https://form.asana.com/?k=Khz0GkkNKXJOdF1RlYneDQ&d=1107193463735611",
      supportCenterHref:
        "https://socotecgroup.sharepoint.com/:f:/s/RapsoSPSV2-Bonnespratiques/EpVEl1vphepOonH1VH5wU_YB8aIaMmR3ogV2qQvYeWwxSg",
      isOnline: navigator.onLine,
      showConfirmOfflineDialog: false,
      lastOnlineStatus: true,
      pending: false,
    };
  },

  async created() {
    if (this.$workbox) {
      // INFO - B.L - 23/03/2022 - waiting event on workbox means a new app was downloaded
      this.$workbox.addEventListener("waiting", () => {
        this.newAppIsAvailable = true;
      });
    }
    await this.fetchTechnicalDoc({
      params: {},
      filtersData: {
        type: "risk",
      },
    });
  },

  computed: {
    ...mapGetters("oidc", ["oidcError", "oidcUser"]),
    cloudIcon() {
      return this.isOnline
        ? "mdi-cloud-check-outline"
        : "mdi-cloud-off-outline";
    },
    cloudIconColor() {
      return this.isOnline ? "green" : "red";
    },
    networkError(){
      return this.oidcError || !this.isOnline
    }
  },
  methods: {
    ...mapActions("notifications", ["showErrorNotification"]),
    ...mapActions("oidc", ["authenticateOidcSilent", "signOutOidc"]),
    ...mapActions("technicalDoc", ["fetchTechnicalDoc"]),
    async update() {
      this.newAppIsAvailable = false;
      // INFO - B.L - 23/03/2022 - This trigger a refresh on the page and load the new app
      await this.$workbox.messageSW({ type: "SKIP_WAITING" });
    },
    openConfirmOfflineDialog: debounce(function (event) {
      if (this.lastOnlineStatus === event) return;
      this.showConfirmOfflineDialog = true;
    }, 200),
    confirmNewOnlineStatus() {
      this.pending = true;
      this.showConfirmOfflineDialog = false;
      if (!navigator.onLine && this.isOnline) {
        this.showErrorNotification(this.$t("networkOffline"));
        this.isOnline = false;
      }
      this.lastOnlineStatus = this.isOnline;
      this.$store.dispatch("network/setNetworkState", this.isOnline);
      this.pending = false;
    },
    cancelNewOnlineStatus() {
      this.showConfirmOfflineDialog = false;
      this.changeOnlineMode();
    },
    changeOnlineMode: debounce(function () {
      this.isOnline = this.lastOnlineStatus;
    }, 100),
    backOnline(){
      if (!navigator.onLine) {
        this.showErrorNotification(this.$t("networkOffline"));
        return;
      }
      this.isOnline = true;
      this.$store.dispatch("network/setNetworkState", this.isOnline);
      this.fetchTechnicalDoc({
        params: {},
        filtersData: {
          type: "risk",
        },
      });
    }
  },
  watch: {
    "$store.state.network.isOnline": function (newVal) {
      if (this.lastOnlineStatus !== newVal)
        this.isOnline = this.lastOnlineStatus = newVal;
    },
  },
};
</script>

<i18n>
{
  "en": {
    "refresh": "Refresh",
    "newAppText": "A new version of RapsoSPS is available, please click refresh.",
    "training_request": "Training request",
    "declare_incident": "Declare an incident",
    "service_request": "Service request",
    "incident_confirmation": "Please note, an 'incident' is a software bug that is blocking for the production of stakeholders",
    "onlineMode": "Online mode",
    "networkOffline": "You are are already offline, please check your internet connection."
  },
  "fr": {
    "refresh": "Rafraîchir",
    "newAppText": "Une nouvelle version de RapsoSPS est disponible, veuillez cliquer sur rafraîchir.",
    "training_request": "Demande de formation complémentaire",
    "declare_incident": "Déclarer un incident",
    "service_request": "Demande de service",
    "incident_confirmation": "Attention, un 'Incident' est un bug logiciel qui est bloquant pour la production des intervenants",
    "onlineMode": "Mode en ligne",
    "networkOffline": "Vous êtes déjà en mode hors ligne, veuillez vérifier votre connexion internet."
  }
}
</i18n>

<style lang="scss" scoped>
#app-main {
  background-color: #f8f9ff;
}
::v-deep .new-app-available .v-snack__wrapper {
  padding: 24px;
}
::v-deep .new-app-available .v-snack__content {
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  font-weight: bold;
  font-size: 1rem;
}
::v-deep .new-app-available .v-snack__action {
  width: 100%;
  margin: 0;
  .v-btn {
    margin: auto;
  }
}
::v-deep div.v-input--switch__track.theme--light.primary--text {
  color: var(--socotec-primary-socotec-yellow-regular, #fcb902) !important;
  caret-color: #ffffff !important;
  opacity: 1;
}
::v-deep div.v-input--switch__thumb.theme--light.primary--text {
  color: #ffffff !important;
  caret-color: #ffffff !important;
  opacity: 1;
}
.warning_icon_container {
  position: relative;
  width: 20px;
  height: 25px;
}
</style>
